<template>
  <div>
    <div class="container-2 w-container">
      <div class="w-layout-grid grid">
        <div id="w-node-f46b2e14a30d-0789c251" class="buttons-container">
          <div class="add-event-button showCursorPointer"
          @click="showImageUpload = true">
            <div class="button-text">Upload New Image</div>
          </div>
        </div>
        <div id="calendar-container" class="calendar-container w-node-d0d89aa4cc2d-0789c251" style="background-color:transparent">
          <v-img
              contain
              :src="imageURL.url" 
              @load="imageLoaded()"
            > <v-progress-circular
           v-if="!isImageLoaded"
      indeterminate
      color="primary"
    ></v-progress-circular></v-img>

        </div>
      </div>
    </div>
    <v-dialog v-model="showImageUpload">
      <image-upload-card
        :multiple="false"
        @save="saveImages"
        @discard="discardClicked"
        
      />
    </v-dialog>
    <v-snackbar v-model="openSnackBar" :color="snackBarColor" timeout="2000">
      <v-row class="pa-0">
        <v-col class="text-center pa-0">{{ snackBarText }}</v-col>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import ImageUploadCard from "../components/Events/ImageUploadCard";
export default {
    name: "StudyClasses",
    components: {
    ImageUploadCard,
  },
  data() {
    return {
      showImageUpload: false,
      images: [],
      openSnackBar: false,
      snackBarColor: "green",
      snackBarText: "",
      imageURL: "",
      isImageLoaded: false
    };
  },
  async mounted() {
      console.log("Getting images")
    await this.getStudyClassesImageUrl();
      console.log("Got image", this.imageURL.url)
  },
  methods: {
    async getStudyClassesImageUrl() {
      console.log("Getting images")
      this.imageURL = await this.$store.dispatch("getStudyClassesImageURL");
      if (this.imageURL === undefined) {
        console.log("Undefined:", this.imageURL);
        this.imageURL = {
          url: null
        }
        
      }
    },
    async saveImages(imageList) {
      this.images = imageList;
      this.imageURL = await this.$store.dispatch("saveStudyClassesImage", this.images[0]);
      console.log("Got image", this.imageURL)
      this.showImageUpload = false;
      this.showSnackBar({
        snackBarText: "Images modified",
        snackBarColor: "green",
      });
    },
    showSnackBar({ snackBarText, snackBarColor }) {
      this.snackBarText = snackBarText;
      this.snackBarColor = snackBarColor;
      this.openSnackBar = true;
    },
    discardClicked() {
      this.showImageUpload = false;
    },
    imageLoaded() {
      console.log("Image loaded")
      this.isImageLoaded = true
    }
  },
};
</script>
<style>
.calendar-container .v-image__image {
  height: 70vh !important;
}
</style>

}
