export default {
  state: {
    loading: false,
    error: null,
    snackBarColor: 'green',
    snackBarText: 'default',
    snackBarTimeout: 2000,
    openSnackBar: false,
    errorDialogOpen: false,
    errorDialogText: '',
    errorDialogTitle: ''
  },
  mutations: {
    setLoading (state, payload) {
      state.loading = payload
    },
    setError (state, payload) {
      state.error = payload
    },
    clearError (state) {
      state.error = null
    },
    setOpenSnackBar (state, payload) {
      state.openSnackBar = payload
    },
    setSnackBarColor (state, payload) {
      state.snackBarColor = payload
    },
    setSnackBarText (state, payload) {
      state.snackBarText = payload
    },
    setSnackBarTimeout (state, payload) {
      state.snackBarTimeout = payload
    },
    setErrorDialogOpen (state, payload) {
      state.errorDialogOpen = payload
    },
    setErrorDialogTitle (state, payload) {
      state.errorDialogTitle = payload
    },
    setErrorDialogText (state, payload) {
      state.errorDialogText = payload
    }
  },
  actions: {
    clearError ({commit}) {
      commit('clearError')
    },
    setOpenSnackBar ({commit}, payload) {
      commit('setOpenSnackBar', payload)
    },
    setSnackBarColor ({commit}, payload) {
      commit('setSnackBarColor', payload)
    },
    setSnackBarText ({commit}, payload) {
      commit('setSnackBarText', payload)
    },
    setSnackBarTimeout ({commit}, payload) {
      commit('setSnackBarTimeout', payload)
    },
    showSnackBar ({commit}, payload) {
      console.log("showing snackbar: ", payload)
      commit('setSnackBarText', payload.text)
      commit('setSnackBarColor', payload.color)
      commit('setSnackBarTimeout', payload.timeout)
      commit('setOpenSnackBar', true)

    },
    setErrorDialogOpen ({commit}, payload) {
      commit('setErrorDialogOpen', payload)
    },
    setErrorDialogTitle ({commit}, payload) {
      commit('errorDialogTitle', payload)
    },
    setErrorDialogText ({commit}, payload) {
      commit('errorDialogText', payload)
    },
    showErrorDialog ({commit}, payload) {
      console.log("showing snackbar: ", payload)
      commit('setErrorDialogTitle', payload.title)
      commit('setErrorDialogText', payload.text)
      commit('setErrorDialogOpen', true)
    },
  },
  getters: {
    loading (state) {
      return state.loading
    },
    error (state) {
      return state.error
    },
    openSnackBar (state) {
      return state.openSnackBar
    },
    snackBarColor (state) {
      return state.snackBarColor
    },
    snackBarText (state) {
      return state.snackBarText
    },
    snackBarTimeout (state) {
      return state.snackBarTimeout
    },
    errorDialogOpen (state) {
      return state.errorDialogOpen
    },
    errorDialogText (state) {
      return state.errorDialogText
    },
    errorDialogTitle (state) {
      return state.errorDialogTitle
    },

  }
}
