<template>
  <v-app id="inspire">
    <div>
      <div
        data-collapse="all"
        data-animation="over-left"
        data-duration="400"
        data-easing="ease-out"
        data-easing2="ease-in"
        role="banner"
        class="navbar-2 w-nav"
      >
        <div class="w-container">
          <a @click="$router.push('/')" class="w-nav-brand"
            ><img
              src="images/vedanta-spain-logo.svg"
              loading="lazy"
              height="48"
              alt=""
              class="image-2"
          /></a>
          <v-navigation-drawer
            v-model="drawer"
            :clipped="$vuetify.breakpoint.smAndUp"
            app
            class="nav-menu w-nav-menu"
          >
            <div @click="$router.push('/')" class="nav-link w-nav-link"
            :class="currentRoute === '/' ? 'w--current' : ''"
            >
              Calendar
            </div>
            <div
              @click="goToPage('/monthly-talks')"
              class="nav-link w-nav-link"
              :class="currentRoute === '/monthly-talks' ? 'w--current' : ''"
            >
              Monthly Talks
            </div>
            <div @click="goToPage('/study-classes')" class="nav-link w-nav-link"
            :class="currentRoute === '/study-classes' ? 'w--current' : ''">
              Study classes
            </div>
             <div @click="$router.push('/settings')" class="nav-link w-nav-link"
            :class="currentRoute === '/settings' ? 'w--current' : ''">
              Settings
            </div>
          
          </v-navigation-drawer>
          <!-- <nav role="navigation" class="nav-menu w-nav-menu">
          <div @click="$router.push('/')" class="nav-link w-nav-link  w--current">Calendar</div>
          <div @click="$router.push('/monthly-talks')" class="nav-link w-nav-link">Monthly Talks</div>
          <div @click="closeNav" class="nav-link w-nav-link">Study classes</div>
        </nav> -->
          <div class="menu-button w-nav-button">
            <div class="w-icon-nav-menu"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.smAndUp"
      app
      class="padding-top: 200px"
    >
      <v-list dense>
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="12">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon
          >
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              link
              router
              :to="item.to"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.text" link router :to="item.to">
            <v-list-item-action>
              <v-icon color="primary">{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.smAndUp"
      app
      color="primary"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title
        @click="$router.push('/')"
        style="width: 300px"
        class="ml-0 pl-4"
      >
        <span class="">Vedanta Admin</span>
      </v-toolbar-title>

      <v-spacer />
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon large v-on="on">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="$router.push('/signout')">
            <v-list-item-title>Sign Out</v-list-item-title>
          </v-list-item>
         
        </v-list>
      </v-menu>
    </v-app-bar> -->
    <v-main>
      <router-view></router-view>
      <v-snackbar
        v-model="openSnackBar"
        :color="snackBarColor"
        :timeout="snackBarTimeout"
      >
        <v-row class="pa-0">
          <v-col class="text-center pa-0">{{ snackBarText }}</v-col>
        </v-row>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-if="!snackBarTimeout"
            color="black"
            text
            v-bind="attrs"
            @click="openSnackBar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-dialog v-model="errorDialogOpen" max-width="290">
        <v-card>
          <v-card-title class="headline">
            {{ errorDialogTitle }}
          </v-card-title>
          <v-card-text>{{ errorDialogText }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="errorDialogOpen = false">
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- <v-snackbar v-model="openSnackBar" :color="snackBarColor" :timeout="snackBarTimeout">
      <v-row class="pa-0">
        <v-col class="text-center pa-0">{{ snackBarText }}</v-col>
      </v-row>
    </v-snackbar> -->
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},
  computed: {
    currentRoute() {
      return this.$route.path;
    },
    snackBarText() {
      return this.$store.getters.snackBarText;
    },
    snackBarColor() {
      return this.$store.getters.snackBarColor;
    },
    snackBarTimeout() {
      return this.$store.getters.snackBarTimeout;
    },
    openSnackBar: {
      get() {
        return this.$store.getters.openSnackBar;
      },
      set(value) {
        this.$store.dispatch("setOpenSnackBar", value);
      },
    },
    userIsAuthenticated() {
      return (
        this.$store.getters.user !== null &&
        this.$store.getters.user !== undefined
      );
    },
    errorDialogOpen: {
      get() {
        return this.$store.getters.errorDialogOpen;
      },
      set(value) {
        this.$store.dispatch("setErrorDialogOpen", value);
      },
    },
    errorDialogTitle() {
      return this.$store.getters.errorDialogTitle;
    },
    errorDialogText() {
      return this.$store.getters.errorDialogText;
    },
  },
  mounted() {
    console.log("Mounted");
    console.log("Current  route:", this.currentRoute);

    // this.addUserAsPremium("mapkbalaji@gmail.com");
    // this.addUserAsPremium("siva2k007@gmail.com");
    // this.addUserAsPremium("mapkbalaji@hotmail.com");
    // this.addUserAsPremium("ramees2@hotmail.com");
    // this.addUserAsPremium("vignesh@techzei.com");
    // this.addUserAsPremium("mapkbalaji@hotmail.com");
  },
  methods: {
    goToPage(page) {
      this.closeNav()
      this.$router.push(page)
    },
    closeNav() {
      var nav = document.getElementsByTagName("nav");
      nav.display = "none !important";
    },
    addUserAsPremium(email) {
      console.log("Adding premium");
      this.$store
        .dispatch("addUserAsPremium", email)
        .then((result) => {
          console.log("Successfully added as admin: ", result);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    },
  },
  data: () => ({
    drawer: true,
    source: "www.google.com",
    items: [
      { icon: "fa-calendar", text: "Calendar", to: "/" },
      {
        icon: "fa-university",
        text: "Monthly Class",
        to: "/monthly-talks",
      },
      {
        icon: "mdi-settings",
        text: "Settings",
        to: "/settings",
      },
    ],
  }),
};
</script>
<style lang="stylus">
@import url('https://fonts.googleapis.com/css?family=Oxygen:400,700&display=swap');

.v-application {
  font-family: 'Oxygen', sans-serif !important;
}

.v-application .primary--text {
  color: #209aa5 !important;
  caret-color: #209aa5 !important;
}

.nav-menu {
  background-color: #209aa5 !important;
}

.v-navigation-drawer__border {
  display: none !important;
}
.w-nav {
  z-index: 100 !important;
}
</style>
