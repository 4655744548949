<template>
	<v-container fluid mt-4 full-height>
		<v-row class="justify-center pt-12">
			<v-col class="text-center" cols="12">
				<span class="d-none d-sm-block display-2 font-weight-bold">SIGN IN</span>
				<span class="d-sm-none headline">SIGN IN</span>
			</v-col>
		</v-row>
		<v-row class="justify-center">
			<v-col class="text-left" cols="12">
				<v-alert
					dismissible
					v-model="showAlertBanner"
					dense
					:type="alertBannerType"
				>{{ alertBannerText }}</v-alert>
			</v-col>
		</v-row>
		<v-row align="center" justify="center" class="ma-md-4 ma-sm-n8 ma-xs-0">
			<v-col class="text-left" cols="12" sm="6">
				<v-card flat color="transparent">
					<v-container>
						<form @submit.prevent="onSignin">
							<v-row>
								<v-col cols="12" class="py-0">
									<v-text-field
										class="px-4"
										name="email"
										label="Email address"
										id="email"
										v-model="email"
										type="email"
										required
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" class="py-0">
									<v-text-field
										class="px-4"
										name="password"
										label="Password"
										id="password"
										v-model="password"
										type="password"
										required
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" md="6" class="text-center">
									<span class="ml-2 caption">
										<router-link to="/forgotpassword">Forgot your password?</router-link>
									</span>
									<!-- <v-btn text class="font-weight-black text-capitalize mx-4">Forgot your password?</v-btn> -->
								</v-col>
								<v-col cols="12" md="6" class="text-md-center text-center">
									<v-btn
										class="font-weight-black black white--text caption font-weight-light"
										:ripple="{ center: true }"
										type="submit"
										:disabled="loading"
										:loading="loading"
									>Sign in</v-btn>
								</v-col>
								<v-col class="text-center">
									<v-btn
										class="font-weight-black black white--text caption font-weight-light"
										:ripple="{ center: true }"
										@click.prevent="$router.push('/signup')"
									>Create account</v-btn>
								</v-col>
							</v-row>
						</form>
					</v-container>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			email: "",
			password: "",
			alertBannerType: "success",
			showAlertBanner: false,
			alertBannerText: ""
		};
	},
	computed: {
		user() {
			return this.$store.getters.user;
		},
		error() {
			return this.$store.getters.error;
		},
		loading() {
			return this.$store.getters.loading;
		}
	},
	/* 	watch: {
		user(value) {
			if (value !== null && value !== undefined) {
				this.$router.push("/");
			}
		}
	}, */
	methods: {
		onSignin() {
			this.$store
				.dispatch("signUserIn", {
					email: this.email,
					password: this.password
				})
				.then(data => {
					console.log("Signed in Data", data);
					if (data === "emailVerification") {
						this.$store.dispatch("logout");
						this.$router.push("/verifyemail");
					} else {
						this.$router.push("/");
					}
				})
				.catch(error => {
					console.log("Error: ", error);
					this.alertBannerType = "error";
					this.alertBannerText = error.message;
					this.showAlertBanner = true;
				});
		},
		onDismissed() {
			this.$store.dispatch("clearError");
		}
	}
};
</script>
<style lang="stylus">
.forgotPasswordText span {
	font-size: 5px;
}
</style>
