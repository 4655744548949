<template>
  <v-container fluid>
    <!-- <v-row>
			<v-col>
				<v-btn @click="getUserDetails">Details</v-btn>
			</v-col>
		</v-row> -->

    <v-row v-if="premiumUser">
      <v-col>
        <v-text-field
          v-model="email"
          type="email"
          label="E-mail"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-btn color="primary" @click="addUserAsPremium"
          >Add Email as admin</v-btn
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col lg="12">
        <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon large v-on="on">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="$router.push('/signout')">
            <v-list-item-title>Sign Out</v-list-item-title>
          </v-list-item>
         
        </v-list>
      </v-menu>
      </v-col>
    </v-row>
   
    <!-- <v-row v-if="premiumUser">
			<v-col>
				<v-btn :loading="deletingUsers" @click="listAllUsers()">Delete Anonymous Users</v-btn>
			</v-col>
		</v-row> -->
  </v-container>
</template>

<script>
export default {
  name: "AddAdmin",
  data() {
    return {
      email: "",
      deletingUsers: false,
    };
  },
  computed: {
    premiumUser() {
      if (this.user) {
        return this.user.premium ? true : false;
      } else {
        return false;
      }
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    getUserDetails() {
      console.log("User: ", this.user);
    },
    async listAllUsers() {
      this.deletingUsers = true;
      var users = await this.$store.dispatch("listAllUsers");

      console.log("Users: ", users);
      await users.data.forEach(async (user) => {
        if (user.email === null) {
          console.log("User: ", user);
          var result = await this.$store.dispatch("deleteUser", user.uid);
          console.log("Deleted user: ", user.uid, result);
        }
      });
      this.deletingUsers = false;
      console.log("For loop completed:");
    },
    addUserAsAdmin() {
      this.$store
        .dispatch("addUserAsAdmin", this.email)
        .then((result) => {
          if (result.data.errorInfo == undefined) {
            console.log("Successfully added as admin: ", result);

            this.$store.dispatch("showSnackBar", {
              text: result.data.message,
              color: "green",
              timeout: 2000,
            });
          } else {
            console.log("Error: ", result);

            this.$store.dispatch("showSnackBar", {
              text: result.data.errorInfo.message,
              color: "red",
              timeout: 2000,
            });
          }
        })
        .catch((error) => {
          console.log("Error: ", error);

          this.$store.dispatch("showSnackBar", {
            text: error.message,
            color: "red",
            timeout: 2000,
          });
        });
    },
    addUserAsPremium() {
      this.$store
        .dispatch("addUserAsPremium", this.email)
        .then((result) => {
          if (result.data.errorInfo == undefined) {
            console.log("Successfully added as premium: ", result);

            this.$store.dispatch("showSnackBar", {
              text: result.data.message,
              color: "green",
              timeout: 2000,
            });
          } else {
            console.log("Error: ", result);
            this.$store.dispatch("showSnackBar", {
              text: result.data.errorInfo.message,
              color: "red",
              timeout: 2000,
            });
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
          this.$store.dispatch("showSnackBar", {
            text: error.message,
            color: "red",
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style>
</style>