<template>
  <div>
    <div class="modal-background" v-if="showNewEventModel">
      <div class="modal">
        <div class="w-layout-grid grid-2">
          <div id="w-node-87659a95e496-2560ad22" class="event-name-container">
            <v-text-field
              label="Event Name"
              placeholder="Event Name"
              solo
              v-model="eventName"
            ></v-text-field>
          </div>
          <!-- Event description -->
          <div id="w-node-26ec51880374-2560ad22" class="event-description">
           <div class="venue-text">
              <v-textarea
                class="descriptionTextArea"
                solo
                name="input-7-4"
                label="Event description"
                placeholder="Event description"
                v-model="description"
                height="72"
              ></v-textarea>
            </div>
          </div>
          <!-- From date -->
          <div id="w-node-578f8bcae9f1-2560ad22" class="event-date-container">
            <v-dialog
              ref="fromDate"
              v-model="fromDateMenu"
              :return-value.sync="fromDateFormatted"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="textFieldDate"
                  label="Event Date"
                  :hint="hintText"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  @blur="dateBlurred()"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" :min="currentDate" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="this.fromDateMenu = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.fromDate.save(fromDateFormatted)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </div>
          <!-- From time -->
          <div id="w-node-c6776dfca1aa-2560ad22" class="from-time-container">
            <v-dialog
              ref="fromTime"
              v-model="fromTimeMenu"
              :return-value.sync="fromTime"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromTime"
                  label="Event Start Time"
                  prepend-icon="mdi-clock-outline"
                  readonly
                  v-bind="attrs"
                  @blur="fromTimeBlurred()"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="fromTimeMenu"
                v-model="fromTime"
                full-width
                format="24hr"
                :min="
                  parseDate(fromDateFormatted) === currentDate
                    ? currentTime
                    : null
                "
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="fromTimeMenu = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.fromTime.save(fromTime)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </div>
          <!-- TO TIME -->
          <div id="w-node-57e39ff53ff0-2560ad22" class="to-time-container">
            <v-dialog
              ref="toTime"
              v-model="toTimeMenu"
              :return-value.sync="toTime"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="toTime"
                  label="Event End Time"
                  prepend-icon="mdi-clock-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="toTimeMenu"
                v-model="toTime"
                full-width
                format="24hr"
                :min="
                  parseDate(fromDateFormatted) === currentDate
                    ? currentTime
                    : null
                "
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="toTimeMenu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.toTime.save(toTime)">
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </div>

          <!-- Radio buttons -->

          <div id="w-node-007414941eac-2560ad22" class="event-type-container">
            <v-radio-group v-model="classMode" row>
              <div class="event-type-item-container event-type-online">
                <div class="radio-button-container">
                  <v-radio value="online"></v-radio>
                </div>
                <div class="event-type-text-container">
                  <div class="event-type-text">Online</div>
                </div>
              </div>
              <!--  <div class="">
                    <div class="radio-button-container"></div>
                    <div class="">
                      <div class="event-type-text">
                        <v-radio label="Online" value="online"></v-radio>
                      </div>
                    </div>
                  </div> -->
              <div class="event-type-item-container event-type-offline">
                <div class="radio-button-container">
                  <v-radio value="offline"></v-radio>
                </div>
                <div class="event-type-text-container">
                  <div class="event-type-text">Offline</div>
                </div>
              </div>
              <!-- <div class="">
                    <div class="radio-button-container"></div>
                    <div class="">
                      <div class="event-type-text">
                        <v-radio label="Offline" value="offline"></v-radio>
                      </div>
                    </div>
                  </div> -->
              <div class="event-type-item-container event-type">
                <div class="radio-button-container">
                  <v-radio value="both"></v-radio>
                </div>
                <div class="event-type-text-container">
                  <div class="event-type-text">Online &amp; Offline</div>
                </div>
              </div>

              <!-- <div class="">
                    <div class="radio-button-container"></div>
                    <div class="">
                      <div class="event-type-text">
                        <v-radio
                          label="Online & Offline"
                          value="both"
                        ></v-radio>
                      </div>
                    </div>
                  </div> -->
            </v-radio-group>
          </div>
          <!-- Venue -->
          <div id="w-node-95e89c0b06f7-2560ad22" class="venue-text-container">
            <div class="venue-text">
              <v-textarea
                class="venueTextArea"
                solo
                name="input-7-4"
                label="Venue"
                placeholder="Venue"
                v-model="venue"
                height="72"
              ></v-textarea>
            </div>
          </div>
          <!-- Registration link -->
          <div id="w-node-befab1523a1a-2560ad22" class="registration-link-container">
            <div class="registration-link-text">
              <v-text-field
                  label="Registration Link"
                  placeholder="Registration Link"
                  v-model="registrationLink"
                  solo
                ></v-text-field>
            </div>
          </div>

          <!-- Zoom -->
          <div id="w-node-cc7ab885f0e6-2560ad22" class="zoom-text-container">
            <div class="zoom-meeting-id-container">
              <div class="zom-meeting-id-text">
                <v-text-field
                  class="meetingIdTextField"
                  label="Zoom Meeting ID"
                  placeholder="Zoom Meeting ID"
                  solo
                  type="number"
                  v-model="meetingID"
                ></v-text-field>
              </div>
            </div>
            <div class="zoom-link-container">
              <div class="zoom-link-text">
                <v-text-field
                  label="Zoom Link"
                  placeholder="Zoom Link"
                  v-model="meetingLink"
                  solo
                ></v-text-field>
              </div>
            </div>
          </div>
          <div
            id="w-node-039b2fe6b6ce-2560ad22"
            class="event-pic-upload-button showCursorPointer"
            @click="showImageUpload = true"
          >
            <!-- <v-file-input
                  @change="filesSelected"
                  truncate-length="15"
                ></v-file-input> -->
            {{ images.length > 0 ? "Change Images" : "Upload Images" }}
          </div>
          <div id="w-node-c44671228b5a-2560ad22" class="whatsapp-dropdown">
            <!--  <v-select
          :items="whatsappPersons"
          :value="whatsappPerson"
          label="Solo field"
          solo
        ></v-select> -->
            <v-select
              :items="whatsappPersons"
              v-model="whatsappPerson"
              name="name"
              item-text="name"
              item-value="value"
              label="Whatsapp"
              return-object
            />
          </div>
          <div
            @click="createButtonClicked"
            id="w-node-c55f34782521-2560ad22"
            class="create-event-button showCursorPointer"
          >
            <div class="create-event-text">
              <v-btn
                @click.stop="createButtonClicked"
                elevation="0"
                :loading="loading"
                :ripple="false"
                class="primary createEventBtn"
                >CREATE EVENT</v-btn
              >
            </div>
          </div>
          <div
            @click="cancelButtonClicked()"
            id="w-node-a9de0bc58535-2560ad22"
            class="delete-event-button-container showCursorPointer"
          >
            <div class="create-event-text">CANCEL</div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="showImageUpload">
      <image-upload-card
        :key="imageUploadCardKey"
        :multiple="false"
        @save="saveImages"
        @discard="discardClicked"
      />
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import ImageUploadCard from "./ImageUploadCard";
export default {
  name: "NewEventDialog",
  components: {
    ImageUploadCard,
  },
  data() {
    return {
      imageUploadCardKey: 0,
      errorText: "",
      hintText: "DD/MM/YYYY format",
      showImageUpload: false,
      eventName: "",
      description: "",
      registrationLink: "",
      meetingID: "",
      meetingLink: "",
      date: new Date().toISOString().substr(0, 10),
      currentDate: this.parseDate(moment().format("DD/MM/YYYY")),
      currentTime: new Date().toISOString().substring(11, 16),
      fromTime: null,
      toTime: null,
      fromDateFormatted: moment().format("DD/MM/YYYY"),
      fromDateMenu: false,
      fromTimeMenu: false,
      toTimeMenu: false,
      classMode: "",
      venue: "",
      uploadedImage: "",
      images: [],
      whatsappPerson: {},
      whatsappPersons: [
        {
          name: "Rishanth",
          value: {
            name: "Rishanth",
            mobile: "+919003018303",
          },
        },
        {
          name: "Katharina",
          value: {
            name: "Katharina",
            mobile: "+919003018303",
          },
        },
      ],
    };
  },
  computed: {
    textFieldDate: {
      get() {
        return (
          moment(this.parseDate(this.fromDateFormatted)).format(
            "dddd, DD MMMM YYYY"
          ) + " CET"
        );
      },
      set(value) {
        this.fromDateFormatted = value;
      },
    },
    showNewEventModel: {
      get() {
        return this.$store.getters.showNewEventModel;
      },
      set(value) {
        this.$store.dispatch("setShowNewEventModel", value);
      },
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    loading() {
      return this.$store.getters.loading;
    },
    calendarEvents() {
      return this.$store.getters.allEvents;
    },
    calendarKey() {
      return this.$store.getters.calendarKey;
    },
  },

  watch: {
    date(val) {
      this.fromDateFormatted = this.formatDate(this.date);
      // this.hintText = moment(this.parseDate(this.fromDateFormatted)).format('dddd, DD MMM YYYY')
    },
  },
  methods: {
    resetForm() {
      this.eventName = "";
      this.description = "";
      this.fromDateFormatted = moment().format("DD/MM/YYYY");
      this.fromTime = "";
      this.toTime = "";
      this.classMode = "";
      this.venue = "";
      this.registrationLink = "";
      this.meetingID = "";
      this.meetingLink = "";
      this.whatsappPerson = {};
      this.images = [];
      this.imageUploadCardKey++;
    },
    cancelButtonClicked() {
      this.showNewEventModel = false;
      this.resetForm();
    },
    async createButtonClicked() {
      if (this.loading) return;
      this.$store.dispatch("setOpenSnackBar", false);
      var event = {
        name: this.eventName,
        description: this.description === "" ? null : JSON.stringify(this.description),
        date: this.fromDateFormatted,
        fromTime: this.fromTime,
        toTime: this.toTime,
        type: this.classMode,
        venue: this.venue === "" ? null : JSON.stringify(this.venue),
        registrationLink: this.registrationLink === "" ? null : this.registrationLink,
        meetingID: this.meetingID === "" ? null : this.meetingID,
        meetingLink: this.meetingLink === "" ? null : this.meetingLink,
        whatsappPerson: this.whatsappPerson,
      };

      var fakeEvent = {
        name: "Fake Event",
        description: "Fake description",
        date: "18/11/2020",
        fromTime: "10:35",
        toTime: "14:36",
        type: "online",
        venue: "Barcelona",
        registrationLink: "https://www.google.com",
        meetingID: "23461629987",
        meetingLink: "https://www.google.com",
        whatsappPerson: {
          name: "Rishanth",
          value: {
            name: "Rishanth",
            mobile: "+919003018303",
          },
        },
      };
      console.log("Fake info: ", fakeEvent);
      console.log("Real info: ", event);
      var newEvent = {
        newEvent: event,
        images: this.images,
      };
      console.log("New Event: ", newEvent);

      if (this.checkForm(newEvent)) {
        console.log("Error found");
        /* this.$store.dispatch("showSnackBar", {
          text: this.errorText,
          color: "red",
          timeout: 0,
        }); */
        this.$store.dispatch("showErrorDialog", {
          title: "Missing Fields",
          text: this.errorText
        });
      } else {
        console.log("no error");
        await this.$store.dispatch("createEvent", newEvent);
        console.log("Component created done");
        this.resetForm();
      }
    },
    checkForm(newEvent) {
      var event = newEvent.newEvent;
      var images = newEvent.images;
      var errorFound = false;
      var firstParam = true;
      var errorText = "Enter the following: ";
      if (event.name === "") {
        if (!firstParam) {
          errorText = errorText + ", ";
        } else {
          firstParam = false;
        }
        errorText = errorText + "Name";
        errorFound = true;
      }

      if (event.fromTime === null) {
        if (!firstParam) {
          errorText = errorText + ", ";
        } else {
          firstParam = false;
        }
        errorText = errorText + "Start Time";
        errorFound = true;
      }
      if (event.toTime === null) {
        if (!firstParam) {
          errorText = errorText + ", ";
        } else {
          firstParam = false;
        }
        errorText = errorText + "End Time";
        errorFound = true;
      }
      if (event.type === "") {
        if (!firstParam) {
          errorText = errorText + ", ";
        } else {
          firstParam = false;
        }
        errorText = errorText + "Event Mode";
        errorFound = true;
      }
      if (event.venue === "") {
        /* if (!firstParam) {
          errorText = errorText + ', '
        } else {
          firstParam = false
        }
        errorText = errorText + 'Venue'
        errorFound = true; */
      }
      if (event.meetingID === "") {
      /*   if (!firstParam) {
          errorText = errorText + ", ";
        } else {
          firstParam = false;
        }
        errorText = errorText + "Meeting ID";
        errorFound = true; */
      }
      if (event.meetingLink === "null") {
       /*  if (!firstParam) {
          errorText = errorText + ", ";
        } else {
          firstParam = false;
        }
        errorText = errorText + "Meeting link";
        errorFound = true; */
      }
      console.log("CP: ", event.whatsappPerson.name);
      if (event.whatsappPerson.name === undefined) {
        if (!firstParam) {
          errorText = errorText + ", ";
        } else {
          firstParam = false;
        }
        errorText = errorText + "Contact Person";
        errorFound = true;
      }
      if (images.length === 0) {
        if (!firstParam) {
          errorText = errorText + ", ";
        } else {
          firstParam = false;
        }
        errorText = errorText + "Upload image";
        errorFound = true;
      }
      if (!errorFound && images.length === 0) {
        errorText = "Upload an image";
        errorFound = true;
      }
      console.log("Error: ", errorText);
      this.errorText = errorText;
      return errorFound;
    },
    dropdownChanged(value) {
      console.log("Dropdown changed: ", value);
      console.log("Dropdown changed: ", this.whatsappPerson);
    },
    saveImages(imageList) {
      this.images = imageList;
      this.showImageUpload = false;
      this.$store.dispatch("showSnackBar", {
        text: "Images modified",
        color: "green",
        timeout: 2000,
      });
    },
    discardClicked() {
      this.showImageUpload = false;
    },
    filesSelected(files) {
      console.log(files);
    },
    previewFiles(event) {
      console.log(event.target.files);
    },
    formatDate(date) {
      if (!date) return null;
      console.log("Format date: ", date);

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    dateBlurred() {
      this.date = this.parseDate(this.fromDateFormatted);
      console.log("Date blurred date: ", this.date);
      console.log("Today date: ", this.fromDateFormatted);
    },
    fromTimeBlurred() {
      console.log("Blur time ");
      console.log("From date: ", this.fromDateFormatted);
      console.log("Today date: ", this.currentDate);
    },
    parseDate(date) {
      if (!date) return null;
      console.log("Parsing date: ", date);

      const [day, month, year] = date.split("/");
      console.log(
        "Parsed date: ",
        `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
      );
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style>
.venueTextArea, .descriptionTextArea {
  font-size: 14px;
}
.venueTextArea .v-label,
.descriptionTextArea .v-label {
  font-size: 14px !important;
}

.zoom-link-text .v-input__slot,
.registration-link-text .v-input__slot,
.event-name-container .v-input__slot {
  min-height: 30px !important;
  box-shadow: none !important;
  background: #ffffff00 !important;
}
.zoom-link-text .v-input__control,
.registration-link-text .v-input__control,
.event-name-container .v-input__control {
  min-height: 30px !important;
  height: 30px !important;
}
.zom-meeting-id-text .v-input__control,
.event-name-container .v-input__control {
  min-height: 30px !important;
  height: 30px !important;
}
.zom-meeting-id-text .v-input__slot {
  min-height: 30px !important;
  box-shadow: none !important;
  background: #ffffff00 !important;
}
.event-name-container .v-input__slot,
.venue-text-container .v-input__slot,
.event-description .v-input__slot {
  min-height: 30px !important;
  box-shadow: none !important;
  background: #ffffff00 !important;
}

.zoom-meeting-id-container .v-text-field__details,
.event-name-container .v-text-field__details,
.event-date-container .v-text-field__details {
  display: none !important;
}
.zoom-meeting-id-container {
  height: 30px !important;
}
.zoom-text-container input,
.event-name-container input {
  font-size: 14px !important;
  color: black !important;
}
.event-name-container .v-text-field__slot input {
  color: black !important;
}
.zoom-text-container {
  padding: 4px 25px !important;
}
.zom-meeting-id-text {
  padding: 5px 0px !important;
}
.event-name-container {
  padding: 4px 25px !important;
}
.event-name-container .v-text-field--outlined .v-label {
  top: 0px;
}
.event-name-container .v-label--active {
  transform: translateY(-14px) scale(0.75);
}
.event-type-container {
  height: 48px !important;
}
.event-type-container .v-input--selection-controls {
  margin-top: 0px;
  padding-top: 4px;
}
.event-type-container .v-input__slot {
  margin-bottom: 0px;
}
.event-type-container .v-input {
  width: 100%;
}
.event-type-container .v-input--radio-group__input {
  justify-content: space-between;
}
.event-type-item-container {
  height: auto !important;
}

.venue-text-container, .event-description {
  padding: 4px 25px;
}
.venue-text-container .v-text-field__details, 
.event-description .v-text-field__details {
  display: none;
}
.event-type-container .v-radio {
  margin-top: 8px !important;
}
.venue-text-container textarea,
.event-description textarea {
  min-height: 40px;
  line-height: 1rem !important;
  font-size: 14px !important;
}
.venue-text-container .v-label,
.event-description .v-label {
  top: 9px !important;
}
.venue-text-container, .event-description {
  height: 90px !important;
}
.whatsapp-dropdown .v-select__selections input {
  max-width: 20px !important;
}
.showCursorPointer {
  cursor: pointer;
}
.calendar-container {
  background-color: transparent !important;
}
.from-time-container .v-text-field,
.to-time-container .v-text-field {
  padding-top: 0px !important;
}
.createEventBtn:focus::before {
  opacity: 0 !important;
}
.showCursorPointer {
  cursor: pointer;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
