import { auth } from '../../firebase/firebaseConfig'
import router from '../../router'
const firebaseConf = require('../../firebase/firebaseConfig')
const addAdminRole = firebaseConf.functions.httpsCallable('addAdminRole')
const addPremiumRole = firebaseConf.functions.httpsCallable('addPremiumRole')
const listAllUsers = firebaseConf.functions.httpsCallable('listAllUsers')
const deleteUser = firebaseConf.functions.httpsCallable('deleteUser')

export default {
  state: {
    user: null
  },
  mutations: {
    setUser(state, payload) {
      console.log("User details: ", payload)
      state.user = payload
      console.log("State user: ", state.user)
    }
  },
  actions: {

    signUserUp({ commit }, payload) {
      commit('setLoading', true)
      commit('clearError')
      var user = null;
      auth.createUserWithEmailAndPassword(payload.email, payload.password)
        .then(result => {
          console.log("User created successfully: ", result)
          user = auth.currentUser;
          console.log("User details: ", user)
          user.sendEmailVerification();
          console.log("Sent email!")
          commit('setLoading', false)

        })
        .then(() => {
          user.updateProfile({
            displayName: payload.name,
            phoneNumber: payload.phone
          });
          console.log("Profile updated");
          const newUser = {
            uid: user.uid,
            name: payload.name,
            email: user.email,
            phoneNumber: payload.phone,
            created_at: Date.now()
          }
          console.log("New User: ", newUser);
          firebaseConf.usersCollection.doc(user.uid).set(newUser)
            .then(result => {
              console.log("Successfully added to database: ", result)
              commit('setLoading', false)
            })
            .catch((error) => {
              commit('setLoading', false)
              console.log(error)
            })


        })
        .catch((error) => {
          commit('setLoading', false)
          console.log(error)
        })


    },
    signUserIn({ commit, dispatch }, payload) {
      commit('setLoading', true)
      commit('clearError')
      return new Promise((resolve, reject) => {

        auth.signInWithEmailAndPassword(payload.email, payload.password)
          .then(
            user => {
              console.log("User: ", user)
              commit('setLoading', false)
              const newUser = user.user

              if (!user.user.emailVerified) {
                auth.currentUser.sendEmailVerification();
                resolve("emailVerification")
              }
              return newUser
            }
          )
          .then((user) => {
            auth.currentUser.getIdTokenResult()
              .then((idTokenResult) => {
                console.log("Admin?: ", idTokenResult.claims.admin)
                console.log("Premium?: ", idTokenResult.claims.premium)

                if (idTokenResult.claims.admin) {
                  user.admin = idTokenResult.claims.admin ? idTokenResult.claims.admin : false
                  user.premium = idTokenResult.claims.premium ? idTokenResult.claims.premium : false
                  commit('setUser', user)
                  resolve(user)
                } else {
                  reject({ message: "User not an admin" })
                  dispatch("logout")
                }
              })
          })
          .catch(
            error => {
              commit('setLoading', false)
              commit('setError', error)
              reject(error)
              console.log(error)
            }
          )

      })



    },
    autoSignIn({ commit }, payload) {
      return new Promise((resolve) => {
        console.log("Auto-Sign in", payload)
        commit('setUser', payload)
        console.log("Auto-Sign in", payload)
        resolve(payload)
      })

    },

    forgotPassword({ commit }, payload) {
      commit('setLoading', true)
      return new Promise((resolve, reject) => {
        auth.sendPasswordResetEmail(payload)
          .then(data => {
            console.log("Successfully sent email", data);
            commit('setLoading', false)
            router.push("/resetpasswordemail");
            resolve(data);
          })
          .catch(error => {
            console.log("Error sending email: ", error)
            commit('setLoading', false)
            reject(error)
          })
      })
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        auth.signOut()
          .then(data => {
            commit('setUser', null)
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      })


    },
    listAllUsers({ commit }) {
      commit('setLoading', false)

      return new Promise(async (resolve) => {
        var result = await listAllUsers();
        resolve(result)
      })
    },
    deleteUser({ commit }, payload) {
      commit('setLoading', false)

      return new Promise(async resolve => {
        var result = await deleteUser(payload);
        resolve(result);
      })
    },

    addUserAsPremium({ commit }, payload) {
      commit('setLoading', false)

      return new Promise((resolve, reject) => {
        addPremiumRole({ email: payload })
          .then(result => {
            console.log(result);
            resolve(result)
          })
          .catch(err => {
            console.log("Error: ", err)
            reject(err)
          })
      })


    },
    addUserAsAdmin({ commit }, payload) {
      commit('setLoading', false)

      return new Promise((resolve, reject) => {
        addAdminRole({ email: payload })
          .then(result => {
            console.log(result);
            resolve(result)
          })
          .catch(err => {
            console.log("Error: ", err)
            reject(err)
          })
      })


    }
  },
  getters: {
    user(state) {
      return state.user
    }
  }
}
