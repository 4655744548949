const firebaseConf = require('../../firebase/firebaseConfig')
const createEvent = firebaseConf.functions.httpsCallable('createEvent')
const deleteEvent = firebaseConf.functions.httpsCallable('deleteEvent')
const listAllEvents = firebaseConf.functions.httpsCallable('listAllEvents')

export default {
    state: {
        saveFinished: false,
        events: [],
        calendarKey: 0,
        showNewEventModel: false,
    },
    mutations: {
        setShowNewEventModel(state, payload) {
            state.showNewEventModel = payload
        },
        setEvents(state, payload) {
            state.events = payload
        },
        setSaveFinished(state, payload) {
            state.saveFinished = payload;
        },
        createEvent(state, payload) {
            console.log("Creating event commit", payload)
            state.events.push(payload)
        },
        deleteEvent(state, payload) {
            return new Promise((resolve) => {
                state.events.forEach((event, index) => {
                    if (event.id === payload) {
                        state.events.splice(index, 1)
                    }
                })
                state.calendarKey++
                resolve()
            })
            
        },
        setOriginalImageUrls(state, payload) {
            return new Promise((resolve) => {
                console.log("Inside start setImageUrls");
                console.log("Update Product: ", payload)
                console.log("Access other file state: ", state.loading);
                var imageUrls = payload.imageUrls
                state.events.map(product => {
                    console.log("Product ID: ", product.id)
                    console.log("Payload key: ", payload.key)
                    console.log("Payload urls: ", imageUrls)
                    if (product.id === payload.key) {
                        console.log("Product found")
                        product.originalUrls = imageUrls.originalUrls;
    
                    } else {
                        console.log("Product not found")
                    }
                });
                console.log("State events: ", state.events);
                resolve()
            })
        },
        updateCalendar(state) {
            state.calendarKey++
        }
    },
    actions: {
        deleteEvent({ commit, dispatch }, payload) {
            commit("setLoading", true)
            console.log("Deleting Event: ", payload);
            return new Promise(async (resolve) => {
                var result = await deleteEvent(payload)
                await commit("deleteEvent", payload)
      dispatch("showSnackBar", {text: "Event deleted", color: "green", timeout: 2000})
                
                console.log("Result: ", result)
                commit("setLoading", false)
                resolve()
            })
        },
        updateCalendar({ commit }) {
            commit('updateCalendar')
        },
        commitAllEvents({ commit }, payload) {
            commit('setEvents', payload);
        },
        listAllEvents({ commit }) {
            commit("setLoading", true)
            console.log("Getting events: ")
            return new Promise(async (resolve) => {

                var events = await listAllEvents();
                console.log("Query snapshot: ", events.data)

                commit("setEvents", events.data)
                commit('updateCalendar')

                commit("setLoading", false)
                resolve(events)

            })
        },
        async createEvent({ commit, dispatch }, payload) {
            return new Promise(async (resolve, reject) => {
                commit('setLoading', true)
            console.log("Creating event", payload)
            var result = await createEvent(payload)
            var key = result.data
            var originalUrl = []
            console.log("Key from result: ", key)
            commit('createEvent', {
                ...payload.newEvent,
                id: key
            })
            // commit('setLoading', false)
            var images = payload.images
            Promise.all(images.map((image, index) => {
                const filename = image.name
                const ext = filename.slice(filename.lastIndexOf('.'))
                console.log("Extension: ", ext)
                dispatch("showSnackBar", { text: "Uploading image", color: "orange", timeout: 2000 })

                return firebaseConf.imageStorageRef.child(key + '_' + index + ext).putString(image.path, 'data_url')
                    .then((fileData) => {
                        console.log("Inner File details: ", fileData)
                        dispatch("showSnackBar", { text: "Image Uploaded", color: "green", timeout: 2000 })

                    })

            }))

                .then(() => {
                    console.log("Everything success")
                    Promise.all(payload.images.map((image, index) => {
                        commit('setLoading', true)

                        const filename = image.name
                        const ext = filename.slice(filename.lastIndexOf('.'))
                        return firebaseConf.imageStorageRef.child(key + '_' + index + ext).getDownloadURL()
                            .then((downloadURL) => {
                                console.log("Download original URL: ", downloadURL)
                                var imageObj = {
                                    defaultImage: image.default,
                                    url: downloadURL
                                }
                                originalUrl.push(imageObj);

                            })
                            .then(async () => {
                                console.log("Original urls: ", originalUrl)

                            })
                            .catch((error) => {
                                console.log("Error: ", error)
                                commit('setLoading', false)
                            })
                    }))
                        .then(async () => {
                            console.log("Final large urls: ", originalUrl)
                            await dispatch("setOriginalImageUrls", { originalUrl, key });
                            dispatch("setOpenSnackBar", false)
                            dispatch("showSnackBar", { text: "Event Created", color: "green", timeout: 2000 })
                            commit('setLoading', false)
                            resolve()

                        })
                        .catch((error) => {
                            console.log("Error: ", error)
                            commit('setLoading', false)
                            reject()
                        })
                })
            })
            
        },
        getMonthlyTalksImageURL({ commit, dispatch }) {
            return new Promise((resolve) => {
                firebaseConf.websiteDataCollection.doc("monthlyTalksImage").get()
                    .then((snapshot) => {
                        console.log("Image: ", snapshot.data())
                        resolve(snapshot.data())
                    })
            })

        },
        getStudyClassesImageURL({ commit, dispatch }) {
            return new Promise((resolve) => {
                firebaseConf.websiteDataCollection.doc("studyClassesImage").get()
                    .then((snapshot) => {
                        console.log("Image: ", snapshot.data())
                        resolve(snapshot.data())
                    })
            })

        },
        async saveMonthlyTalksImage({ commit, dispatch }, payload) {
            return new Promise((resolve) => {

                commit('setLoading', true)
                console.log("Uploading ")
                var image = payload
                const filename = image.name
                return firebaseConf.imageStorageRef.child(filename).putString(image.path, 'data_url')
                    .then((fileData) => {
                        console.log("File uploaded: ", fileData)
                        return firebaseConf.imageStorageRef.child(filename).getDownloadURL()
                            .then((downloadURL) => {
                                console.log("Download original URL: ", downloadURL)
                                var url = downloadURL
                                firebaseConf.websiteDataCollection.doc("monthlyTalksImage").update({
                                    url: downloadURL
                                })
                                    .then(() => {
                                        console.log("Successfully modified monthly talks: ", url)
                                        commit('setLoading', false)
                                        resolve({ url: url })
                                    })
                            })
                        /* firebaseConf.productsCollection.doc("monthlyTalksPic").update({
                            url:
                        }) */
                    })
            })


        },
        async saveStudyClassesImage({ commit, dispatch }, payload) {
            return new Promise((resolve) => {

                commit('setLoading', true)
                console.log("Uploading ")
                var image = payload
                const filename = image.name
                return firebaseConf.imageStorageRef.child(filename).putString(image.path, 'data_url')
                    .then((fileData) => {
                        console.log("File uploaded: ", fileData)
                        return firebaseConf.imageStorageRef.child(filename).getDownloadURL()
                            .then((downloadURL) => {
                                console.log("Download original URL: ", downloadURL)
                                var url = downloadURL
                                firebaseConf.websiteDataCollection.doc("studyClassesImage").update({
                                    url: downloadURL
                                })
                                    .then(() => {
                                        console.log("Successfully modified monthly talks: ", url)
                                        commit('setLoading', false)
                                        resolve({ url: url })
                                    })
                            })
                        /* firebaseConf.productsCollection.doc("monthlyTalksPic").update({
                            url:
                        }) */
                    })
            })


        },
        setOriginalImageUrls({ commit, dispatch }, payload) {
            return new Promise((resolve, reject) => {
                commit("setLoading", true)
            console.log("Setting Image URLs: ", payload)
            firebaseConf.eventsCollection.doc(payload.key).update({
                originalUrls: payload.originalUrl
            })
                .then(async (data) => {
                    var imageUrls = {
                        originalUrls: payload.originalUrl
                    }
                    var key = payload.key

                    console.log("Updated database: ", data)
                    commit('setSaveFinished', true);
                    console.log("Save finished");
                    console.log("Outside start setImageUrls");
                    await dispatch("commitSetImageUrls", { imageUrls, key });
                    // await commit("setImageUrls", )
                    console.log("Outside end setImageUrls");
                    commit('updateCalendar')
                    commit('setShowNewEventModel', false)
                    resolve()
                })
                .catch((error) => {
                    console.log(error)
                    reject()
                })
            })
            
        },
        commitSetImageUrls({ commit }, payload) {
            return new Promise(async (resolve) => {
                commit("setLoading", true)

                console.log("Inside start commitSetImageUrls");
                var imageUrls = payload.imageUrls;
                var key = payload.key;
                await commit("setOriginalImageUrls", { imageUrls, key });
                // commit("setLoading", false)
                console.log("Inside end commitSetImageUrls");
                resolve();
            })
        },
        setShowNewEventModel({ commit }, payload) {
            commit("setShowNewEventModel", payload)
        }
    },

    getters: {
        events(state) {
            return state.events
        },
        calendarKey(state) {
            return state.calendarKey
        },
        showNewEventModel(state) {
            return state.showNewEventModel
        }
    }
}
