import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import './registerServiceWorker'
import './registerServiceWorker'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import VueLazyLoad from 'vue-lazyload'
import '@fortawesome/fontawesome-free/css/all.css'
import moment from 'moment-timezone'
moment.tz.setDefault('Europe/Madrid')

const fb = require('./firebase/firebaseConfig')

Vue.config.productionTip = false
Vue.use(VueLazyLoad)

new Vue({
  router,

  vuetify,
  render: h => h(App),
  created () {
    console.log("Created main.js")
    fb.auth.onAuthStateChanged(async (user) => {
      if (user) {
        console.log("User: ", user)
        await fb.auth.currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            console.log("Admin?: ", idTokenResult.claims.admin)
            console.log("Premium?: ", idTokenResult.claims.premium)

            if (idTokenResult.claims.admin) {
              user.admin = idTokenResult.claims.admin ? idTokenResult.claims.admin : false
              user.premium = idTokenResult.claims.premium ? idTokenResult.claims.premium : false
              this.$store.dispatch('autoSignIn', user)

            } else {
              this.$store.dispatch("logout")
            }
          })
        // this.$store.dispatch('fetchUserData')
      }
    })
  },
  store,
}).$mount('#app')
