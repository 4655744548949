<template>
	<v-container fluid full-height>
		<v-row class="justify-center pt-12">
			<v-col class="text-center" cols="12">
				<span class="d-none d-sm-block display-2 font-weight-bold">RESET PASSWORD</span>
				<span class="d-sm-none headline">RESET PASSWORD</span>
			</v-col>
		</v-row>
		<form @submit="onForgotPassword">
			<v-row class="justify-center pt-12">
				<v-col class="text-center" cols="12" sm="6">
					<v-text-field name="email" label="Mail" id="email" v-model="email" type="email" required></v-text-field>
				</v-col>
				<v-col class="text-center" cols="12">
					<v-btn type="submit" :disabled="loading" :loading="loading">Sign up</v-btn>
				</v-col>
			</v-row>
		</form>
	</v-container>
</template>

<script>
export default {
	name: "ForgotPassword",
	data() {
		return {
			email: "",
			sentEmail: false
		};
	},
	computed: {
		emailParams() {
			if (this.$route.query.email) {
				return this.$route.query.email;
			} else {
				return null;
			}
		},
		loading() {
			return this.$store.getters.loading;
		}
	},
	mounted() {
		console.log("Router param: ", this.emailParams);
		if (this.emailParams) {
			this.$router.push("/resetpasswordemail");
		}
	},
	methods: {
		onForgotPassword() {
			console.log("Email: ", this.email);
			this.$store
				.dispatch("forgotPassword", this.email)
				.then(result => {
					console.log("Sent mail: ", result);
					this.$router.push("/resetpasswordemail");
				})
				.catch(error => {
					console.log("Error: ", error);
				});
		}
	}
};
</script>

<style>
</style>