import { store } from '../store/index.js'
const fb = require('../firebase/firebaseConfig')

export default (to, from, next) => {
  fb.auth.onAuthStateChanged(async (user) => {
    if (user) {
      console.log("User: ", user)
      await store.dispatch('autoSignIn', user)
      next()
      console.log("Next", store.getters.user)
      // this.$store.dispatch('fetchUserData')
    } else {
      console.log("Else", store.getters.user)
      next('/signin')
    }
  })

}
