import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import MonthlyTalks from '../views/Monthly-Talks.vue'
import StudyClasses from '../views/Study-Classes.vue'
import SignIn from '../components/Users/Signin.vue'
import SignOut from '../components/Users/SignOut.vue'
import Signup from '../components/Users/Signup'
import ForgotPassword from '../components/Users/ForgotPassword'
import VerifyEmail from '../components/Users/VerifyEmail'
import ResetPasswordEmail from '../components/Users/ResetPasswordEmail'
import Settings from '../components/Settings'

import AuthGuard from './auth-guard'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      beforeEnter: AuthGuard
    },
    {
      path: '/signin',
      name: 'SignIn',
      component: SignIn,
    },
    {
      path: '/signout',
      name: 'SignOut',
      component: SignOut,
    },
    {
      path: '/signup',
      name: 'Signup',
      component: Signup
    },
    {
      path: '/verifyemail',
      name: 'VerifyEmail',
      component: VerifyEmail
    },
    {
      path: '/resetpasswordemail',
      name: 'ResetPasswordEmail',
      component: ResetPasswordEmail
    },
    {
      path: '/forgotpassword',
      name: 'ForgotPassword',
      component: ForgotPassword
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Settings,
      beforeEnter: AuthGuard
    },
    {
      path: '/monthly-talks',
      name: 'MonthlyTalks',
      component: MonthlyTalks,
      beforeEnter: AuthGuard
    },
    {
      path: '/study-classes',
      name: 'Study Classes',
      component: StudyClasses,
      beforeEnter: AuthGuard
    },
  ],
  mode: 'history'
})
