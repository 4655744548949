<template>
  <div class="home">
    <div>
      <div class="container-2 w-container">
        <div class="w-layout-grid grid">
          <div id="w-node-f46b2e14a30d-2560ad22" class="buttons-container">
            <div class="add-event-button showCursorPointer">
              <div class="button-text" @click="showNewEventModel = true">
                Add New Event
              </div>
            </div>
          </div>
          <div
            id="calendar-container"
            class="calendar-container w-node-d0d89aa4cc2d-2560ad22"
          >
            <calendar-component :calendarStyle="'height: 65vh'" :admin="true" :key="calendarKey" :events="calendarEvents" />
          </div>
        </div>
      </div>
      <v-dialog v-model="showNewEventModel" class="padding-top: 64">
        <new-event-dialog></new-event-dialog>
      </v-dialog>
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import CalendarComponent from "../components/Events/CalendarComponent"
import NewEventDialog from '../components/Events/NewEventDialog.vue';
export default {
  name: "Home",
  components: {
    CalendarComponent,
    NewEventDialog
  },
  data() {
    return {
      
    };
  },
  async mounted() {
      await this.$store.dispatch("listAllEvents");
  },
  computed: {
   
        calendarEvents() {
      return this.$store.getters.allEvents;
    },
    calendarKey() {
      return this.$store.getters.calendarKey;
    },
    showNewEventModel: {
      get() {
      return this.$store.getters.showNewEventModel;

      },
      set(value) {
        this.$store.dispatch("setShowNewEventModel", value)
      }
     
    },
    
  },

  watch: {
   
  },

  
};
</script>
<style>
.showCursorPointer {
  cursor: pointer;
}
</style>
