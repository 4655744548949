<template>
	<v-container fluid full-height>
		<v-row class="justify-center pt-12">
			<v-col class="text-center" cols="12">
				<span class="d-none d-sm-block display-2 font-weight-bold">NEW ACCOUNT</span>
				<span class="d-sm-none headline">NEW ACCOUNT</span>
			</v-col>
		</v-row>
		<v-row class="justify-center">
			<v-col class="text-left" cols="12">
				<v-alert
					dismissible
					v-model="showAlertBanner"
					dense
					:type="alertBannerType"
				>{{ alertBannerText }}</v-alert>
			</v-col>
		</v-row>
		<v-row align="center" justify="center" class="ma-md-4 ma-sm-n8 ma-xs-0">
			<v-col class="text-left" cols="12">
				<v-container>
					<form @submit.prevent="onSignup">
						<v-row>
							<v-col cols="12" sm="6" class="py-0">
								<v-text-field
									name="firstName"
									label="First Name"
									id="firstName"
									v-model="newUser.firstName"
									type="text"
									required
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" class="py-0">
								<v-text-field
									name="lastName"
									label="Last Name"
									id="lastName"
									v-model="newUser.lastName"
									type="text"
									required
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" class="py-0">
								<v-text-field
									name="phoneNumber"
									label="Phone Number"
									id="phoneNumber"
									v-model="newUser.phoneNumber"
									type="phone"
									required
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" class="py-0">
								<v-text-field
									name="email"
									label="Mail"
									id="email"
									v-model="newUser.email"
									type="email"
									required
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" class="py-0">
								<v-text-field
									name="password"
									label="Password"
									id="password"
									v-model="newUser.password"
									type="password"
									required
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" class="py-0">
								<v-text-field
									name="confirmPassword"
									label="Confirm Password"
									id="confirmPassword"
									v-model="newUser.confirmPassword"
									type="confirmPassword"
									:rules="[comparePasswords]"
									required
								></v-text-field>
							</v-col>
							<!-- <v-col cols="12" sm="12" class="py-0">
								<v-file-input
									label="Upload profile picture (optional)"
                  accept="image/*"
									v-model="files"
									@change="fileSelected"
								></v-file-input>
							</v-col>-->
							<v-col cols="12" sm="12" class="text-center">
								<v-btn type="submit" :disabled="loading" :loading="loading">Sign up</v-btn>
							</v-col>
						</v-row>
					</form>
				</v-container>
			</v-col>
		</v-row>
	</v-container>
	<!--  <v-container>
    <v-layout row v-if="error">
      <v-flex xs12 sm6 offset-sm3>
        <app-alert @dismissed="onDismissed" :text="error.message"></app-alert>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12 sm6 offset-sm3>
        <v-card>
          <v-card-text>
            <v-container>
              <form @submit.prevent="onSignup">
                <v-layout row>
                  <v-flex xs12>
                    <v-text-field
                      name="email"
                      label="Mail"
                      id="email"
                      v-model="email"
                      type="email"
                      required></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs12>
                    <v-text-field
                      name="password"
                      label="Password"
                      id="password"
                      v-model="password"
                      type="password"
                      required></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs12>
                    <v-text-field
                      name="confirmPassword"
                      label="Confirm Password"
                      id="confirmPassword"
                      v-model="confirmPassword"
                      type="password"
                      :rules="[comparePasswords]"></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs12>
                    <v-btn type="submit" :disabled="loading" :loading="loading">
                      Sign up
                       <span slot="loader" class="custom-loader">
                        <v-icon light>cached</v-icon>
                       </span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
	</v-container>-->
</template>

<script>
export default {
	data() {
		return {
			email: "",
			password: "",
			confirmPassword: "",
			alertBannerType: "success",
			alertBannerText: "",
			showAlertBanner: false,
			newUser: {}
		};
	},
	computed: {
		comparePasswords() {
			return this.newUser.password !== this.newUser.confirmPassword
				? "Passwords do not match"
				: "";
		},
		user() {
			return this.$store.getters.user;
		},
		error() {
			return this.$store.getters.error;
		},
		loading() {
			return this.$store.getters.loading;
		}
	},

	methods: {
		onSignup() {
			this.$store.dispatch("signUserUp", {
				name: this.newUser.firstName + " " + this.newUser.lastName,
				email: this.newUser.email,
				phone: this.newUser.phoneNumber,
				password: this.newUser.password
			});
		},
		onDismissed() {
			this.$store.dispatch("clearError");
		}
	}
};
</script>
