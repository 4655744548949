<template>
	<v-container>Sign Out</v-container>
</template>

<script>
export default {
	mounted() {
		this.$store.dispatch("logout").then(() => {
			this.$router.push("/signin");
		});
	}
};
</script>

<style>
</style>