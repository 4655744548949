<template>
	<v-container fluid>
  <v-row class="justify-center">
          <v-col class="text-center" cols="12">
						<v-card class="pa-5">
							<v-card-title>Images</v-card-title>
							<div
								id="my-strictly-unique-vue-upload-multiple-image"
								style="display: flex; justify-content: center;"
							>
								<vue-upload-multiple-image
									@upload-success="uploadImageSuccess"
									@before-remove="beforeRemove"
									@edit-image="editImage"
									@data-change="dataChange"
									@mark-is-primary="primaryImageChanged"
									dragText="Drop your images here (or)"
									browseText="Browse"
									primaryText="Primary"
									markIsPrimaryText="Mark as Primary"
									popupText="This image would be displayed as first"
									:maxImage="15"
                                    :multiple="multiple"
                                    :dataImages="dataImages"
                                    :showDelete="true"
								></vue-upload-multiple-image>
							</div>
                            <v-card-actions>
                                <v-row class="justify-end px-8">
			<v-col cols="6" sm="2" class="text-center">
				<v-btn class="error" @click="discardClicked">Discard</v-btn>
			</v-col>
			<v-col cols="6" sm="2" class="text-center">
				<v-btn @click="saveClicked" :disabled="loading"
                    :loading="loading"  class="primary">Save</v-btn>
			</v-col>
		</v-row>
                            </v-card-actions>
						</v-card>
					</v-col>
                   
		</v-row>
       
        <!--  -->
	</v-container>
</template>

<script>
import VueUploadMultipleImage from "vue-upload-multiple-image";
export default {
name: "ImageUploadCard",
	components: {
		VueUploadMultipleImage
    },
    data() {
		return {
            images: [],
            dataImages: []

        }
    },
    computed: {
    loading() {
      return this.$store.getters.loading;
    },
  },
    props: ['multiple'],
    methods: {
        discardClicked() {
            this.$emit("discard");
            console.log(this.dataImages);
            this.images = []
        },
        saveClicked() {
            this.$emit("save", this.images);
        },
        uploadImageSuccess(formData, index, fileList) {
			console.log("data", formData, index, fileList);
			this.images = fileList;
			// Upload image api
			// axios.post('http://your-url-upload', formData).then(response => {
			//   console.log(response)
			// })
        },
        beforeRemove(index, done, fileList) {
			console.log("index", index, fileList);
			var r = confirm("Remove image?");
			if (r === true) {
				done();
				this.images = fileList;
			}
		},
		editImage(formData, index, fileList) {
			console.log("edit data", formData, index, fileList);
			this.images = fileList;
		},
		dataChange(formData, index, fileList) {
			console.log("edit data", formData, index, fileList);
			this.images = fileList;
		},
		primaryImageChanged(index, fileList) {
			console.log("Primary image changed", index, fileList);
			this.images = fileList;

		}
    }
}
</script>

<style>

</style>