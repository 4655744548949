<template>
	<v-container fluid full-height>
		<v-row class="justify-center pt-12">
			<v-col class="text-center" cols="12">
				<span class="d-none d-sm-block display-2 font-weight-bold">CHECK EMAIL</span>
				<span class="d-sm-none headline">CHECK EMAIL</span>
			</v-col>
			<v-col class="text-center" cols="12">
				<span class>
					Password reset link has been sent to your mail. Please follow the link to reset the password and
					<router-link to="/signin">Sign-in</router-link>&nbsp; again to enjoy shopping!
				</span>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: "ResetPasswordEmail",
	data() {
		return {};
	},
	methods: {}
};
</script>

<style>
</style>
