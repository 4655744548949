import firebase from 'firebase'

// firebase init goes here
const config = {
  apiKey: "AIzaSyDTy6d_YICuH28HYueWhut0hsOXgGEUsJw",
  authDomain: "vedanta-c8d36.firebaseapp.com",
  databaseURL: "https://vedanta-c8d36.firebaseio.com",
  projectId: "vedanta-c8d36",
  storageBucket: "vedanta-c8d36.appspot.com",
  messagingSenderId: "1065525281833",
  appId: "1:1065525281833:web:a226f8006903f4e57f0838",
  measurementId: "G-QVJX7YTD8L"
}
firebase.initializeApp(config)

// firebase utils
const db = firebase.firestore()
const auth = firebase.auth()
const currentUser = auth.currentUser
const functions = firebase.functions();
const storage = firebase.storage();
const imageStorage = firebase.app().storage("gs://vedanta-images")

// firestore collections
const usersCollection = db.collection("users")
const productsCollection = db.collection("products")
const eventsCollection = db.collection("events")
const websiteDataCollection = db.collection("websiteData")
const meetupsCollection = db.collection("meetups")

const imageStorageRef = imageStorage.ref();

export {
  functions,
  db,
  auth,
  storage,
  currentUser,
  usersCollection,
  productsCollection,
  eventsCollection,
  websiteDataCollection,
  meetupsCollection,
  imageStorageRef

}
